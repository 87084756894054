<template>
  <b-form-select :value="value" @input="handleInput" id="page-select">
    <b-form-select-option :value="20">{{ $t('PAGINATION_RESULTS_PER_PAGE', {size: 20}) }}</b-form-select-option>
    <b-form-select-option :value="50">{{ $t('PAGINATION_RESULTS_PER_PAGE', {size: 50}) }}</b-form-select-option>
    <b-form-select-option :value="100">{{ $t('PAGINATION_RESULTS_PER_PAGE', {size: 100}) }}</b-form-select-option>
    <b-form-select-option :value="1000">{{ $t('PAGINATION_RESULTS_PER_PAGE', {size: 1000}) }}</b-form-select-option>
  </b-form-select>
</template>

<script>
import {VModel} from "@/mixins";

export default {
  name: "PageSize",
  mixins: [VModel]
}
</script>

<style scoped>

</style>
