<template>
  <div>
    <div class="pb-3">
      <b-button @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') | capitalize }}</span>
      </b-button>

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL')  | capitalize}}</span>
      </b-button>
      <!-- clear button -->

      <download-excel class="ml-1 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.sessions"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL')  | capitalize}}
      </download-excel>
    </div>

    <!-- Page size -->
    <div class="d-inline-block w-25">
      <page-size v-model="size" class="mb-1"></page-size>
    </div>
    <!-- Page size -->

    <!-- List page filter -->
    <session-list-filter class="d-inline-block w-100 mb-1" @search="setFilter" v-model="filter"></session-list-filter>
    <!-- List page filter -->

    <b-table
        id="sessions-table"
        ref="sessionsTable"
        v-model="items"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        :current-page="page"
        :filter="filter"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('SESSIONS')}) }}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <template #head(username)="">
        <span>{{ labels.username  | capitalize}}</span>
      </template>

      <template #cell(username)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.username }}</span>
        </div>
      </template>

      <template #head(ip)="">
        <span>{{ labels.ip  | capitalize}}</span>
      </template>

      <template #cell(ip)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.ip }}</span>
        </div>
      </template>

      <template #head(type)="">
        <span>{{ labels.type  | capitalize}}</span>
      </template>

      <template #cell(type)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ $t(data.item.type + '_LABEL') }}</span>
        </div>
      </template>

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize}}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(userAgent)="">
        <span>{{ labels.userAgent  | capitalize}}</span>
      </template>

      <template #cell(userAgent)="data">
        <div style="text-align: center">{{ data.item.userAgent }}</div>
      </template>

      <template #head(validThru)="">
        <span>{{ labels.validThru  | capitalize}}</span>
      </template>

      <template #cell(validThru)="data">
        <div style="text-align: center">{{ data.item.validThru | date('DD/MM/YYYY HH:mm:ss') }}</div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown v-if="data.item.enabled" size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'SESSION_DELETE_PERMISSION'" @click="disable(data.item.id, data.item.username)">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize}}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="sessions-table"
          ></b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {DateFilter, Table} from "@/mixins";
import Sessions from "@/users/sessions/index";
import eventBus from "@/events";
import Constants from "@/constants";
import SessionListFilter from "@/users/sessions/SessionListFilter";
import PageSize from "@/components/PageSize";

export default {
  name: "SessionList",
  components: {PageSize, SessionListFilter},
  props: ['selectMode', 'currentlySelected'],
  mixins: [Table, DateFilter],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_SESSION_LIST, (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {},
  data() {
    return {
      labels: {
        username: this.$t('SESSION_USERNAME_LABEL'),
        ip: this.$t('SESSION_IP_LABEL'),
        type: this.$t('SESSION_TYPE_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        userAgent: this.$t('SESSION_USER_AGENT_LABEL'),
        validThru: this.$t('SESSION_EXPIRATION_LABEL'),
        users: this.$t('SESSIONS'),
        excel: this.$t('SESSIONS') + '.xls',
      },
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'username', sortable: true, tdClass: 'customTd'},
        {key: 'ip', sortable: true, tdClass: 'customTd'},
        {key: 'type', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'customTd'},
        {key: 'userAgent', sortable: true, tdClass: 'customTd'},
        {key: 'validThru', sortable: true, tdClass: 'customTd'},
        'options'];
    },
    excelFields() {
      const fields = {};

      fields[this.labels.username] = 'username';
      fields[this.labels.ip] = 'ip';
      fields[this.labels.type] = 'type';
      fields[this.labels.enabled] = 'enabled';
      fields[this.labels.userAgent] = 'userAgent';
      fields[this.labels.validThru] = 'validThru';

      return fields;
    },
    tableRef() {
      return this.$refs.sessionsTable;
    },
    controller() {
      return Sessions;
    }
  }
}
</script>

<style>
.selectTd {
  width: 15px !important;
}
</style>
