<template>
  <div>
    <b-form inline>
      <div class="w-25">
        <b-form-datepicker
            @input="search"
            v-model="filter.from"
            right
            locale="es-MX"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            button-variant="dark"
            aria-controls="example-input"
            :label-no-date-selected="$t('SEARCH_FROM_DATE_LABEL') | capitalize"></b-form-datepicker>
      </div>

      <div class="w-25 pl-1">
        <b-form-datepicker
            @input="search"
            v-model="filter.to"
            right
            locale="es-MX"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            button-variant="dark"
            aria-controls="example-input"
            :label-no-date-selected="$t('SEARCH_TO_DATE_LABEL') | capitalize"></b-form-datepicker>
      </div>

      <div class="w-25 pl-1">
        <b-form-input @blur="search" v-model="filter.text" class="w-100" :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('SESSION')}) | capitalize"
                      id="search-input"></b-form-input>
      </div>

      <div class="w-25 pl-1">
        <b-form-select @change="search" class="w-100" v-model="filter.status" id="status-select">
          <b-form-select-option :value="undefined">{{ $t('FILTER_ALL_LABEL', {catalog: $t('SESSIONS'), gender: 'a'})  | capitalize}}</b-form-select-option>
          <b-form-select-option :value="true">{{ $t('FILTER_ACTIVE_LABEL', {catalog: $t('SESSIONS'), gender: 'a'}) | capitalize }}</b-form-select-option>
          <b-form-select-option :value="false">{{ $t('FILTER_INACTIVE_LABEL', {catalog: $t('SESSIONS'), gender: 'a'}) | capitalize }}</b-form-select-option>
        </b-form-select>
      </div>
    </b-form>
  </div>
</template>

<script>
import {CapitalFilter, Filter, VModel} from "@/mixins";

export default {
  name: "SessionListFilter",
  mixins: [Filter, VModel, CapitalFilter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      this.$emit('search', this.filter);
    },
    resetFilter() {
      this.filter = {};
    }
  }
}
</script>

<style scoped>

</style>
